<template>
  <div class="category-management-wrapper container-fluid " :dir="isRTL ? 'rtl' : 'ltr'">
    <!-- Action Buttons -->
    <div class="row mb-4 " :class="{ 'text-left': !isRTL, 'text-right': isRTL }">
      <div class="col-md-6">
        <button class="our_btn btn btn-success" @click="openAddCategoryDialog">
          <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.add_category") }}
        </button>
        <button class="import_btn btn btn-primary ml-3" @click="handleImportWords">
          <el-icon class="el-icon-upload"></el-icon>
          {{ $t("admin_dashboard_type.import_excel") }}

        </button>
      </div>

      <!-- Search Bar -->
      <div class="col-md-6 " :class="{ 'text-left': !isRTL, 'text-right': isRTL }">
        <el-input
          v-model="searchTerm"
          :placeholder="$t('admin_dashboard_type.search_by_word')"
          clearable
          @keyup.enter.native="handleSearch"
          class="search-bar"
        >
          <template v-slot:append>
            <el-button @click="handleSearch" icon="el-icon-search" type="primary">
              {{ $t("admin_dashboard_type.search") }}
            </el-button>
          </template>
        </el-input>
      </div>
    </div>

    <!-- Search Results -->
    <div v-if="searchResults && searchResults.length > 0" class="search-results ">
      <h3 :class="{ 'text-left': !isRTL, 'text-right': isRTL }" >{{ $t("admin_dashboard_type.search_results") }}:</h3>
      <ul :class="{ 'text-left': !isRTL, 'text-right': isRTL }">
        <li v-for="(result, index) in searchResults" :key="index">
          <strong>{{ $t("admin_dashboard_type.category") }}:
          </strong> {{ result.category || $t('admin_dashboard_type.not_found') }}<br>
          <!-- <strong>Subcategory:</strong> {{ result.subcategory }}<br>
          <strong>Word:</strong> {{ result.word }}<br> -->
        </li>
        <li v-for="(result, index) in searchResults" :key="index">
          <!-- <strong>Category:</strong> {{ result.category }}<br> -->
          <strong>{{ $t("admin_dashboard_type.subcategory") }}:
          </strong> {{ result.subcategory|| $t('admin_dashboard_type.not_found') }}<br>
          <!-- <strong>Word:</strong> {{ result.word }}<br> -->
        </li>
        <li v-for="(result, index) in searchResults" :key="index">
        <!-- <strong>Category:</strong> {{ result.category }}<br> -->
          <!-- <strong>Subcategory:</strong> {{ result.subcategory }}<br> -->
          <strong>{{ $t("admin_dashboard_type.word") }}:
          </strong> {{ result.word }}<br>
        </li>
      </ul>
    </div>
    <div v-else class="no-results" :class="{ 'text-right': !isRTL, 'text-left': isRTL }">
      <p>{{ $t("admin_dashboard_type.no_match")}}</p>
    </div>

    <!-- Category and Subcategory Table -->
    <div class="table-responsive">
      <el-table :data="categories" border stripe style="width: 100%" text-center>
  <!-- Category Name Column -->
  <el-table-column prop="name" :label="$t('admin_dashboard_type.category_name')" />
  <!-- Category Counter Column -->
  <el-table-column prop="category_counter" :label="$t('admin_dashboard_type.category_count')" />

  <!-- Subcategory Name Column -->
  <el-table-column prop="subcategories" :label="$t('admin_dashboard_type.subcategory_name')">
    <template v-slot="scope">
      <!-- Iterate over subcategories to display their names -->
      <div v-for="(subcategory, index) in scope.row.subcategories" :key="index">
        {{ subcategory.name }}
      </div>
    </template>
  </el-table-column>

  <!-- Subcategory Counter Column -->
  <el-table-column prop="subcategory_counter" :label="$t('admin_dashboard_type.subcategory_count')">
    <template v-slot="scope">
      <!-- Iterate over subcategories to display their counters -->
      <div v-for="(subcategory, index) in scope.row.subcategories" :key="index">
        {{ subcategory.subcategory_counter }}
      </div>
    </template>
  </el-table-column>

  <!-- Delete icom   -->
  <el-table-column label="Action">
  <template slot-scope="scope">
    <div class="icons_table_wrapper">
      <i
        @click="removeCategory(scope.row.id)"
        class="fa fa-trash shadow-sm icon remove"
      ></i>
    </div>
  </template>
  </el-table-column>
</el-table>

    </div>

    <!-- Add Category Dialog -->
    <el-dialog
      :visible.sync="showCategoryDialog"
      :title="$t('admin_dashboard_type.add_category')"
      width="50%"
      :close-on-click-modal="false"
      :class="{ 'text-left': !isRTL, 'text-right': isRTL }"
    >
      <el-form
        :model="categoryData"
        :rules="categoryRules"
        ref="categoryForm"
        label-position="top"
        class="category-form"
      >
        <el-form-item :label="$t('admin_dashboard_type.category_name')" prop="name">
          <el-input v-model="categoryData.name" />
        </el-form-item>
        <el-form-item :label="$t('admin_dashboard_type.subcategory_name')" prop="subcategory_name">
          <el-input v-model="categoryData.subcategory_name" />
        </el-form-item>
        <el-form-item :label="$t('admin_dashboard_type.words')" prop="words">
          <div v-for="(word, index) in categoryData.words" :key="index">
            <el-input
              v-model="categoryData.words[index]"
              :placeholder="$t('admin_dashboard_type.placeholder')"

            />
          </div>
          <el-button
            @click="addWord"
            icon="el-icon-plus"
            type="primary"
            size="mini"
          >
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="danger" @click="closeCategoryDialog">{{ $t("admin_dashboard_type.cancel") }}</el-button>
        <el-button type="primary" @click="handleCategorySubmit">
          {{ $t('admin_dashboard_type.save_category') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapActions, mapState,mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      showCategoryDialog: false,
      categoryData: {
        name: "",
        subcategory_name: "",
        words: [""],
      },
      categoryRules: {
        name: [{ required: true, message: "Category name is required" }],
        subcategory_name: [{ required: true, message: "Subcategory name is required" }],
        words:[{ required: true, message: "word is required" }]
      },
      searchTerm: "",
    };
  },
  computed: {
    ...mapState("categories", [
      "loading",
      "errors",
      "results",
      "getCategories",

    ]),
    ...mapGetters("categories",["getCategories"," getResults"]),
    categories() {
    console.log("Categories in Computed Property:", this.getCategories);
    return this.getCategories || [];
  },
  searchResults() {
    console.log("result in Computed Property:", this.getResults);
    return this.results || [];
  },
  isRTL() {
    return this.$i18n.locale === "ar";
  }
  },
  methods: {
    ...mapActions("categories", [
      "categoryManagement",
      "searchWord",
      "importWords",
      "showCategoriesAndSubcategories",
    ]),

    async fetchCategories() {
      try {
        await this.showCategoriesAndSubcategories();
      } catch (error) {
        Swal.fire("Error", "Failed to load categories", "error");
      }
    },

    async handleSearch() {
  this.$store.commit("categories/SET_RESULTS", []);

  try {
    const payload = { word: this.searchTerm.trim() };  // Pass the word or other search params
    await this.$store.dispatch("categories/searchWord", payload);  // Dispatch action

    const searchResults = this.$store.state.categories.results;  // Access updated results
  //  if (searchResults && searchResults.length > 0) {
  //     Swal.fire("Success", "Word found successfully", "success");
  //   } else {
  //     Swal.fire("Not Found", "No results for the search term", "info");
  //   }

    console.log("Search Results after dispatch:", searchResults);  // Debugging
  } catch (error) {
    Swal.fire("error", "Word is not found ", "error");

    console.error("Search error:", error);
  }
},

    async handleCategorySubmit() {
      const isValid = await this.$refs.categoryForm.validate();
      if (!isValid) {
        Swal.fire("Error", "Please fill in all required fields.", "error");
        return;
      }

      try {
        const categoryData = {
          category_name: this.categoryData.name, // Ensure correct field name
          subcategory_name: this.categoryData.subcategory_name, // Ensure correct field name
          word: this.categoryData.words.join(" "), // Join words into a single string
        };

        await this.categoryManagement(categoryData); // Send to backend
        Swal.fire("Success", "Category added successfully", "success");
        this.fetchCategories();
        this.closeCategoryDialog();
      } catch (error) {
        Swal.fire("Error", "Failed to add category", "error");
      }
    },

    async handleImportWords() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".xls,.xlsx";
      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const formData = new FormData();
            formData.append("file", file);
            await this.importWords(formData);
            // Swal.fire("Success", "Words imported successfully", "success");
            Swal.fire("Success", "{{ $t('admin_dashboard_type.save_category') }}", "success");
            this.fetchCategories();
          } catch (error) {
            Swal.fire("Error", "Failed to import words", "error");
          }
        }
      };
      input.click();
    },

    closeCategoryDialog() {
      this.showCategoryDialog = false;
    },

    openAddCategoryDialog() {
      this.resetCategoryData();
      this.showCategoryDialog = true;
    },

    resetCategoryData() {
      this.categoryData = {
        name: "",
        subcategory_name: "",
        words: [""],
      };
    },

    // formatSubcategories(subcategories) {
    //   return subcategories.map((sub) => sub.name).join(", ");
    // },

    addWord() {
      this.categoryData.words.push("");
    },
    removeCategory(id) {
      console.log("Deleting category with ID:", id);
    Swal.fire({
      text: "By deleting this category, you will also delete its subcategories and words.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success btn-fill",
      cancelButtonClass: "btn btn-danger btn-fill",
      confirmButtonText: this.$t("confirm"),
      cancelButtonText: this.$t("cancel"),
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {

        this.$store.dispatch("categories/deleteCategory",id)
          .then(() => {
            Swal.fire({
              text: "Category, subcategories, and words deleted successfully.",
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              text: "Failed to delete category: " + error.message,
              icon: "error",
              confirmButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            });
          });
      }
    });
  },
  },
  mounted() {
    console.log("Fetching categories...");
    this.fetchCategories();
  },
};
</script>

<style lang="scss" scoped>
.category-management-wrapper {
  .icons_table_wrapper {
    justify-content: start;
    padding: 20px 0;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      &.remove {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    button {
      margin-right: 1rem;
      padding: 0.7rem 1.5rem;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      &.our_btn {
        background-color: #4CAF50; // Green
        color: white;
        border: none;
        &:hover {
          background-color: #45a049;
        }
      }

      &.import_btn {
        background-color: #2196F3; // Blue
        color: white;
        border: none;
        &:hover {
          background-color: #1976D2;
        }
      }
    }
  }

  .search-bar {
    margin-bottom: 1rem;
    .el-input__inner {
      border-radius: 20px;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-color: #ddd;
      transition: all 0.3s;
      &:focus {
        border-color: #4CAF50;
        box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
      }
    }
    .el-button {
      background-color: #4CAF50; // Green
      color: white;
      border-radius: 50px;
      &:hover {
        background-color: #45a049;
      }
    }
  }

  .search-results {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f0f4f7;
    border-radius: 5px;
  }

  .no-results {
    text-align: center;
    padding: 1rem;
    color: #999;
  }

  .el-table {
    margin-top: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    .el-table__header-wrapper {
      background-color: #4CAF50; // Green
      color: white;
    }
    .el-table__body {
      background-color: #ffffff;
    }
    .el-table-column {
      padding: 1rem;
    }
  }

  .category-form .el-form-item {
    margin-bottom: 1rem;
  }

  .category-form .el-input {
    width: 100%;
    font-size: 1rem;
  }

  .el-dialog {
    .el-dialog__body {
      padding: 2rem;
      background-color: #fafafa;
    }
    .el-dialog__footer {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      .el-button {
        padding: 0.8rem 2rem;
      }
    }
  }
  [dir="rtl"] {
  text-align: right ;
  direction: rtl t;

  .el-input {
    direction: rtl ;
  }


  .search-bar {
    .el-input__inner {
      text-align: right ;
    }
  }
}
}

</style>

